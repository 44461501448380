<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <Breadcrumb :datas='breadcrumb_data'/>
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <!-- begin::Card-->
                <div class="card card-custom overflow-hidden">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="card-label">{{ $t('f_management.deposit_transactions') }}</h3>
                      </div>
                      <div class="card-toolbar">
                        <!--begin::Button-->
                        <router-link 
                          to="/finance/deposit"
                          class="btn btn-sm btn-secondary text-primary font-weight-bold"
                        >
                          <i class="flaticon2-back text-primary fa-1x"></i> {{ $t("commons.back") }}
                        </router-link>
                        <!--end::Button-->
                      </div>
                    </div>
                    <div class="card-body p-0">
                        <!-- begin: Invoice-->
                        <!-- begin: Invoice header-->
                        <div class="row justify-content-center py-8 px-8 py-md-15 px-md-0">
                            <div class="col-md-10">
                                <div class="border-bottom w-100"></div>
                                <div class="d-flex justify-content-between pt-6">
                                    <div class="row w-100">
                                      <div class="col-md-6 col-sm-12">
                                        <div class="row">
                                          <p class="col-md-4 col-sm-12">{{ $t('commons.name') }} {{ $t('commons.surname') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ userFullName }}</b>
                                        </div>
                                        <div class="row mt-2">
                                          <p class="col-md-4 col-sm-12">{{ $t('c_management.identity_no') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ user.identity_number }}</b>
                                        </div>
                                        <div class="row mt-2">
                                          <p class="col-md-4 col-sm-12">{{$t('commons.cost') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.amount_formatted }}</b>
                                        </div>
                                        <div class="row mt-2">
                                          <p class="col-md-4 col-sm-12">{{ $t('commons.status') }}:</p>
                                          <b class="col-md-8 col-sm-12">
                                              <span class="badge" :class="statusClass">
                                                  {{ statusLabel }}
                                              </span>
                                          </b>
                                        </div>
                                        <div class="row mt-2" v-if="+deposit.status === 40">
                                          <p class="col-md-4 col-sm-12">{{$t('f_management.reason_for_cancellation') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.reason || '-' }}</b>
                                        </div>
                                        <div class="row mt-2">
                                          <p class="col-md-4 col-sm-12">{{$t('f_management.date_request') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.created_at }}</b>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-sm-12">
                                        <div class="row">
                                          <p class="col-md-4 col-sm-12">{{ $t('f_management.transaction_number') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.code || '-' }}</b>
                                        </div>
                                        <div class="row mt-2" v-if="deposit.provider">
                                          <p class="col-md-4 col-sm-12">{{ $t('f_management.payment_method') }}:</p>
                                          <b class="col-md-8 col-sm-12 text-break">{{ deposit.provider.name || '-' }}</b>
                                        </div>
                                        <div class="row mt-2" v-if="deposit.provider">
                                          <p class="col-md-4 col-sm-12">{{ $t('finance.bank') }}:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.provider.bank || '-' }}</b>
                                        </div>
                                        <div class="row mt-2" v-if="deposit.provider">
                                          <p class="col-md-4 col-sm-12">IBAN:</p>
                                          <b class="col-md-8 col-sm-12">{{ deposit.provider.address || '-' }}</b>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="border-bottom w-100 pt-6"></div>
                            </div>
                        </div>
                        <!-- end: Invoice header-->
                    </div>
                </div>
                <!-- end::Card-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from '@/components/layout/Breadcrumb.vue'

export default {
  name: 'withdraw-detail',
  data() {
    return { }
  },
  components: {
    Breadcrumb
  },
  computed: {
    ...mapState({
      deposit: (state) => state.depositTransaction.detailsModalData.deposit,
    }),
    user() {
        return this.deposit?.user || {};
    },
    userFullName() {
        const { name, surname } = this.user;
        if(name && surname) return `${name} ${surname}`
        return '-';
    },
    statusClass() {
        switch (this.deposit?.status) {
            case 10:
                return 'badge-warning';
            case 20:
            case 70:
                return 'badge-light';
            case 30:
                return 'badge-success';
            case 40:
            case 50:
            case 60:
                return 'badge-danger';
            default:
                return '';
        }
    },
    statusLabel() {
        if(this.deposit?.status) {
            return this.$t(`f_management.statuses.${this.deposit.status}`)
        }
        return '-'
    },
    breadcrumb_data() {
      return {
        title:  this.$t('f_management.title'),
        subtitle: [
          this.$t('f_management.deposit_transactions')
        ]
      }
    }
  },
  created() {
      this.$store.dispatch('depositTransaction/GET_DETAILS_MODAL', this.$route.params.id)
  }
}
</script>